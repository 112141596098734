function init() {
    const peoplePage = document.querySelector<HTMLElement>('.js-people-page');

    const setDataTheme = () => {
        if (peoplePage) {
            peoplePage.dataset.theme = window.matchMedia('(min-width: 1200px)').matches ? 'detail' : 'dark';
        }
    };

    setDataTheme();

    window.addEventListener('resize', setDataTheme);
}

const _module = { init };

export default _module;
